import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import Container from './container'
import * as styles from './footer.module.css'

const Footer = () => {
  const { contentfulFooter: data } = useStaticQuery(
    graphql`
      query FooterQuery {
        contentfulFooter {
          contactNumber
          contactEmail
          copyrightText
          address
          body {
            childMarkdownRemark {
              html
            }
          }
          image {
            title
            gatsbyImageData
          }
        }
      }
    `
  )

  return (
    <footer>
      <div className={styles.footer}>
        <Container className={styles.container}>
          <GatsbyImage
            alt="The Square Interiors"
            image={data.image.gatsbyImageData}
            objectFit="contain"
            style={{ width: 100, height: 100 }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: data.body.childMarkdownRemark.html,
            }}
            style={{ width: '320px' }}
          ></div>
          <div>
            <h4>Address</h4>
            <p>{data.address}</p>
          </div>
          <div>
            <h4>Email</h4>
            <p>{data.contactEmail}</p>
            <h4>Phone Number</h4>
            <p>{data.contactNumber}</p>
          </div>
        </Container>
      </div>
      <Container>
        <div className={styles.footNote}>
          <div>Built with ❤️ by bomaqs</div>
          <div>{data.copyrightText}</div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
