import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import * as styles from './hero.module.css'

const Container = ({ children }) => (
  <div className={styles.details}>{children}</div>
)

const Hero = ({ image, title, content, ContentContainer = Container }) => (
  <div className={styles.hero}>
    {image && (
      <GatsbyImage className={styles.image} alt={title} image={image} />
    )}
    <ContentContainer>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.divider} />
      {content && (
        <p
          className={styles.content}
          dangerouslySetInnerHTML={{
            __html: content?.childMarkdownRemark?.excerpt,
          }}
        />
      )}
    </ContentContainer>
  </div>
)

export default Hero
